import "./App.css";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDu0X0sf2R3JJeC2_xZ_cJoPrnE4tF_5-Q",
  authDomain: "website-3c884.firebaseapp.com",
  projectId: "website-3c884",
  storageBucket: "website-3c884.appspot.com",
  messagingSenderId: "262992447190",
  appId: "1:262992447190:web:ba0e03e09f0b7459ae6b77",
  measurementId: "G-10RN0S9S0J",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);

function App() {
  return (
    <div className="App">
      <h1>Under construction</h1>
    </div>
  );
}

export default App;
